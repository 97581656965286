import { connect, ItemType } from 'datocms-plugin-sdk'
import 'datocms-react-ui/styles.css'
import ConfigScreen from './entrypoints/ConfigScreen'
import Remotion from './entrypoints/Remotion'
import { render } from './utils/render'

connect({
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />)
  },

  // declare intent to add panel to the sidebar
  itemFormSidebarPanels(model: ItemType) {
    // console.log(model)

    // if(model.attributes.api_key !== 'video_overlay'){
    //   return []
    // }

    return [
      {
        id: 'remotion',
        label: 'Remotion',
        startOpen: true,
      },
    ]
  },

  renderItemFormSidebarPanel(sidebarPaneId, ctx) {
    if (sidebarPaneId === 'remotion') {
      return render(<Remotion ctx={ctx} />)
    }
  },
})
