import { RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk'
import { Button, Canvas } from 'datocms-react-ui'

type PropTypes = {
  ctx: RenderItemFormSidebarPanelCtx
}

export default function Remotion({ ctx }: PropTypes) {
  console.log('-> remotion')
  const item = ctx.item
  const sessionId = ctx.item?.attributes.session_id
  console.log(item)

  return (
    <Canvas ctx={ctx}>
      <Button
        buttonType="primary"
        onClick={async () => {
          console.log('clicked for ' + item?.id)
          const url = `https://admin.mindday.com/api/github/proxy-dispatch`
          await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              event_type: 'trigger_datocms_remotion_generate_overlay',
              client_payload: { id: sessionId },
            }),
          })
            .then((resp) => resp.json())
            .finally(() => {
              alert('Triggered')
            })
        }}
      >
        Render overlay
      </Button>
    </Canvas>
  )
}
